<script setup lang="ts">
import {
  onBeforeMount,
  toRef,
} from 'vue';

import {
  onComponentActivated,
  onComponentDeactivated,
} from '@leon-hub/vue-utils';

import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
import CasinoHomePromoBlock from 'web/src/modules/home/submodules/casino/components/CasinoHomePromoBlock/CasinoHomePromoBlock.vue';
import { CasinoHomeBlockPriority } from 'web/src/modules/home/submodules/casino/enums';
import { useCasinoHome } from 'web/src/modules/home/submodules/casino/useCasinoHome';
import ShowcaseNavigationRouteComponent
  from 'web/src/modules/home/submodules/showcase/ShowcaseNavigationRouteComponent.vue';
import { SportlineHomeBlockPriority } from 'web/src/modules/home/submodules/sportline/enums';
import MixedSportlineEventsBlock
  from 'web/src/modules/home/submodules/sportline/views/sportline-element/MixedSportlineEventsBlock.vue';
import SportlineEventsBlock
  from 'web/src/modules/home/submodules/sportline/views/sportline-element/SportlineEventsBlock.vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';

const isTopEventsEnabled = toRef(() => useSportlineSettingsStore().isTopEventsEnabled);

const { setBackgroundUpdateEnabled } = useCasinoHome();
const favoritesEventsStore = useFavoritesEventsStore();

onBeforeMount(() => {
  void favoritesEventsStore.initialRequests();
});
onComponentActivated(() => {
  void setBackgroundUpdateEnabled(true);
});
onComponentDeactivated(() => {
  void setBackgroundUpdateEnabled(false);
});

useKeepAlive();
</script>

<template>
  <div v-auto-id="'CasinoHomeRoutePage'" class="component-wrapper">
    <ShowcaseNavigationRouteComponent />
    <template v-if="isTopEventsEnabled">
      <MixedSportlineEventsBlock />
      <CasinoHomePromoBlock :priority="CasinoHomeBlockPriority.Jackpots" />
      <div :class="$style['home-block-delimiter']" />
      <CasinoHomePromoBlock :priority="CasinoHomeBlockPriority.Promotions" />
    </template>
    <template v-else>
      <SportlineEventsBlock :priority="SportlineHomeBlockPriority.Live" />
      <CasinoHomePromoBlock :priority="CasinoHomeBlockPriority.Jackpots" />
      <SportlineEventsBlock :priority="SportlineHomeBlockPriority.Prematch" />
      <CasinoHomePromoBlock :priority="CasinoHomeBlockPriority.Promotions" />
    </template>
  </div>
</template>

<style module lang="scss">
.home-block-delimiter {
  height: 2px;
}
</style>
