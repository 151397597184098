import { RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult } from '@core/router';
import { useRootNavigationStore } from '@core/navigation';
import { AbstractPrefetch } from '@core/router';

import { useCasinoHome } from 'web/src/modules/home/submodules/casino/useCasinoHome';

export default class CasinoHomePagePrefetch extends AbstractPrefetch {
  prefetch(): AbstractPrefetchResult {
    const {
      isReady,
      initialRequests,
      syncBackgroundRequests,
    } = useCasinoHome();

    const navigationStore = useRootNavigationStore();

    if (!isReady.value) {
      navigationStore.setOnlyFirstRouteContentLoading(RouteName.HOME);
      initialRequests().then(() => {
        navigationStore.setRouteContentLoaded(RouteName.HOME);
      }).catch((error) => {
        throw error;
      });
    } else {
      void syncBackgroundRequests();
      navigationStore.setRouteContentLoaded(RouteName.HOME);
    }
  }
}
